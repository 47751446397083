<template>
  <div>
    <div>
      <h4>选择设备型号:</h4>
    </div>
    <div>
      <el-row class="tac" :gutter="10">
        <el-col :span="3">
          <el-menu default-active="1" class="el-menu-vertical-demo">
            <template v-for="(item,index) in models">
              <el-menu-item :index="index" :key="index">
                <i class="el-icon-set-up"></i>
                <span>{{item.title}}</span>
              </el-menu-item>
            </template>
          </el-menu>
        </el-col>
        <el-col :span="20">
          <!--设备修改内容-->
          <div class="block" style="height: 80vh;overflow-y:scroll;">
            <el-timeline>
              <template v-for="(item,index) in vers">
                <el-timeline-item type="success" size="large" :timestamp="item.ver" placement="top" :key="index">
                  <el-card>
                    <el-row :gutter="20">
                      <el-col :span="20">
                        <p v-if="item.changes != void 0">
                          <span class="ver-iconfont icon-change"></span>
                          <span style="font-weight:bold">改动：</span>
                          <span style="margin-right:15px" v-for="(changesItem,cIndex) in item.changes" :key="cIndex">{{cIndex+1}}.{{changesItem}}</span>
                        </p>
                        <p v-if="item.bugFixed != void 0">
                          <span class="ver-iconfont icon-bugFixed"></span>
                          <span style="font-weight:bold">bug修复：</span>
                          <span style="margin-right:15px" v-for="(bugFixedItem,bIndex) in item.bugFixed">{{bIndex+1}}.{{bugFixedItem}}</span>
                        </p>
                        <p v-if="item.new != void 0">
                          <span class="ver-iconfont icon-new"></span>
                          <span style="font-weight:bold">新增：</span>
                          <span style="margin-right:15px" v-for="(newItem,nIndex) in item.new">{{nIndex+1}}.{{newItem}}</span>
                        </p>
                        <p v-if="item.bugs != void 0">
                          <span class="ver-iconfont icon-bugs"></span>
                          <span style="font-weight:bold">bugs：</span>
                          <span style="margin-right:15px" v-for="(bugsItem,bugIndex) in item.bugs">{{bugIndex+1}}.{{bugsItem}}</span>
                        </p>
                      </el-col>
                      <el-col :span="4">
                        <!-- <el-divider direction="vertical"></el-divider> -->
                        <div style="margin:30px">
                          <el-button icon="el-icon-download" size="mini" type="success" plain @click="handleEdit()">固件下载</el-button>
                        </div>
                      </el-col>
                    </el-row>

                  </el-card>
                </el-timeline-item>
              </template>
            </el-timeline>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- <div>
      <el-dialog title="ver 2023.10.13" :visible.sync="dialogTableVisible">
        <el-table :data="gridData">
          <el-table-column property="date" label="日期" width="150"></el-table-column>
          <el-table-column property="name" label="姓名" width="200"></el-table-column>
          <el-table-column property="address" label="地址"></el-table-column>
        </el-table>
      </el-dialog>
    </div> -->
  </div>

</template>

<script>
export default {
  data() {
    return {
      profile: {},
      models: [{
        "model": "dmWater",
        "title": "Linux地埋网关",
      },
      {
        "model": "dmWater",
        "title": "扬尘",
      },
      {
        "model": "dmWater",
        "title": "圆形水位版",
      },
      {
        "model": "dmWater",
        "title": "地埋",
      },],
      deviceData: [],
      // page
      page: {
        sizes: 20,
        size: 10,
        curPage: 1
      },
      dialogTableVisible: false,
      vers: [{
        ver: '2023.09.14',
        url: "2023/09.14/app.tar.gz",
        changes: ['修复A错误一处', '修复B错误一处', '修复B错误一处'],
        bugFixed: ['频繁上下线，可能是PHY驱动能力不够可能是PHY驱动能力不够', '解决办法:修改uboot，增强PHY驱动能力', "不能自动上报数据，需要用磁铁激活", "可能是PHY驱动能力不够", "可能是PHY驱动能力不够", "可能是PHY驱动能力不够"],
        new: ["对接广西平台", "测试485", "测试485"],
        bugs: ["不能自动上报数据，需要用磁铁激活"]

      }, {
        ver: '2023.09.13',
        url: "2023/09.13/app.tar.gz",
        bugFixed: ['频繁上下线，可能是PHY驱动能力不够', '解决办法:修改uboot，增强PHY驱动能力'],
        new: ["对接广西平台", "测试485", "测试485"],
        bugs: ["不能自动上报数据，需要用磁铁激活"]
      },
      {
        ver: '2023.09.12',
        url: "2023/09.13/app.tar.gz",
        changes: ['修复A错误一处', '修复B错误一处'],
        bugFixed: ['频繁上下线，可能是PHY驱动能力不够', '解决办法:修改uboot，增强PHY驱动能力'],
        new: ["对接广西平台", "测试485", "测试485"],
        bugs: ["不能自动上报数据，需要用磁铁激活"]
      }, {
        ver: '2023.09.11',
        url: "2023/09.13/app.tar.gz",
        changes: ['修复A错误一处', '修复B错误一处'],
        bugFixed: ['频繁上下线，可能是PHY驱动能力不够', '解决办法:修改uboot，增强PHY驱动能力'],
        new: ["对接广西平台", "测试485", "测试485"],
        bugs: ["不能自动上报数据，需要用磁铁激活"]
      }, {
        ver: '2023.09.11',
        url: "2023/09.13/app.tar.gz",
        changes: ['修复A错误一处', '修复B错误一处'],
        new: ["对接广西平台", "测试485", "测试485"],
        bugs: ["不能自动上报数据，需要用磁铁激活"]
      }]
      // {
      //   id: 2,
      //   ver: '2023.09.13',
      //   url: "2023/09.13/app.tar.gz",
      //   changes: ['修复A错误一处', '修复B错误一处', '修复B错误一处'],
      //   bugFixed: ['频繁上下线，可能是PHY驱动能力不够,解决办法:修改uboot，增强PHY驱动能力'],
      //   new: ["对接广西平台", "测试485", "测试485"],
      //   bugs: ["不能自动上报数据，需要用磁铁激活"]
      // }
    }
  },
  methods: {
    valFormatter(row, column, cellValue, index) {

      return cellValue.length
    },
    setValue(row) {
      let text = ''

      row.forEach((item, index) => {
        text = text + (index + 1) + '.' + item + "；"
      });
      console.log(111, text);

      return text
    },
    handleEdit() {
      let arr = [{
        ver: '2023.09.22',
        url: "2023/09.14/app.tar.gz",
        changes: ['221', '123', '123'],
        bugFixed: ['123123', '解决办法:修改uboot，增强PHY驱动能力', "不能自动上报数据，需要用磁铁激活", "可能是PHY驱动能力不够", "可能是PHY驱动能力不够", "可能是PHY驱动能力不够"],
        new: ["对接广西平台", "测试485", "测试485"],
        bugs: ["不能自动上报数据，需要用磁铁激活"]

      }, {
        ver: '2023.09.23',
        url: "2023/09.13/app.tar.gz",
        changes: ['修复A错误一处', 'qweq', '修复B错误一处'],
        bugFixed: ['qweqweqweqwe,解决办法:修改uboot，增强PHY驱动能力'],
        new: ["对接广西平台", "测试485", "测试485"],
        bugs: ["qweqweqwewqeqw"]
      }]
      this.vers = arr
    }
  }
}
</script>

<style>
@import "../../assets/iconfont/icon_vers.css";
.el-card__body {
  padding: 2px 0 0 10px;
}
.el-timeline-item__timestamp {
  color: #3a3a3a;
  font-size: 15px;
  font-weight: bold;
}
.icon-bugFixed:before {
  color: #f3c66e;
}

.icon-bugs:before {
  color: #73dcd8;
}

.icon-new:before {
  color: #3cc56e;
}

.icon-change:before {
  color: #5d7cf6;
}
.ver-iconfont {
  margin-right: 5px;
}
</style>